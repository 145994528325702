<template>
  <div class="content">
    <div class="card" style="display: flex">
      <div class="left">
        <el-button
          type="primary"
          @click="dialogVisible = true"
          icon="el-icon-plus"
          >新增打印模板</el-button
        >
      </div>
    </div>
    <!-- <p><font size="3"><b>演示自定义纸张：</b></font></p>
    <p>1：用宽<input type="text" size="5" value="1380" id="W1">(138mm)高<input type="text" size="4" value="880" id="H1">(88mm)
的动态纸张<a @click="Preview1()">打印预览1</a>一份交行受理书，打印方向不定。</p>
  <div style="width: 100%;" id="box">
    <div style="width: 100%; height: 3.6cm; border: 1px solid; box-sizing: border-box;">
    1234156
    </div>
  </div> -->
    <!-- 山东黄金标签一口价 -->
    <div id="label6" v-if="false">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <!-- 此处循环标签 -->
        <div style="width: 2.9cm; height: 3.372cm; margin-top: 0.2cm; position: relative;" >
          <!-- 背景图 -->
          <!-- <div
            :style="{
                position: 'absolute',
                inset: 0,
                background: 'url(' + bg + ')',
                backgroundSize: '100% 100%',
              }"
            >
          </div> -->
          <div style="width: 100%; text-align: center; position: absolute; top: 0.50cm; font-size: 12px; transform: scale(0.6);">18K耳饰</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.8cm; font-size: 12px; transform: scale(0.5); white-space: nowrap;">一口价：￥284700.00</div>
          <!-- <div style="position: absolute; font-size: 12px; transform: scale(0.3); top: 1.7cm; left: -0.7cm; width: 100%">123415645</div>
          <div style="position: absolute; font-size: 12px; transform: scale(0.3); top: 1.7cm; right: -0.2cm;">总重: 3.80g</div> -->
          <div style="width: 1.9cm; height: 0.3cm; position: absolute; top: 1.95cm; left: 0.5cm;">
            <img src="" style="width: 100%; height: 100%;" />
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.23cm; font-size: 12px; transform: scale(0.5);">123456789</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.4cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.4); ">检测依据: GB 11887 GB/T18043</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.58cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市山金矿业贵金属有限公司</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.75cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市罗湖区水贝珠宝工业园</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.9cm; white-space: nowrap; display: flex; justify-content: flex-start;">
            <div style="font-size: 12px; transform: scale(0.41); ">4006106998</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 山东黄金标签钻石 -->
    <div id="label5" v-if="false">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <!-- 此处循环标签 -->
        <div style="width: 2.9cm; height: 3.372cm; margin-top: 0.2cm; position: relative;" >
          <!-- 背景图 -->
          <div
            :style="{
                position: 'absolute',
                inset: 0,
                background: 'url(' + bg + ')',
                backgroundSize: '100% 100%',
              }"
            >
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.50cm; font-size: 12px; transform: scale(0.6);">18K耳饰</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.8cm; font-size: 12px; transform: scale(0.6);">￥284700.00</div>
          <div style="position: absolute; font-size: 12px; transform: scale(0.3); top: 1.7cm; left: -0.7cm;"></div>
          <div style="position: absolute; font-size: 12px; transform: scale(0.3); top: 1.7cm; right: -0.2cm;">总重: 3.80g</div>
          <div style="width: 1.9cm; height: 0.3cm; position: absolute; top: 1.95cm; left: 0.5cm;">
            <img src="" style="width: 100%; height: 100%;" />
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.23cm; font-size: 12px; transform: scale(0.5);">123456789</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.4cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.4); ">检测依据: GB 11887 GB/T18043</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.58cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市山金矿业贵金属有限公司</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.75cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市罗湖区水贝珠宝工业园</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.9cm; white-space: nowrap; display: flex; justify-content: flex-start;">
            <div style="font-size: 12px; transform: scale(0.41); ">4006106998</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 山东黄金金条标签 -->
    <div id="label4" v-if="false">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <!-- 此处循环标签 -->
        <div style="width: 2.9cm; height: 3.372cm; margin-top: 0.2cm; position: relative;" >
          <!-- 背景图 -->
          <!-- <div
            :style="{
                position: 'absolute',
                inset: 0,
                background: 'url(' + bg + ')',
                backgroundSize: '100% 100%',
              }"
            >
          </div> -->
          <div style="width: 100%; text-align: center; position: absolute; top: 0.38cm; font-size: 12px; transform: scale(0.7);">投资金条</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.61cm; font-size: 12px; transform: scale(0.5);">含金量: 999%</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.83cm; font-size: 12px; transform: scale(0.6);">净质量: 5.93g</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 1.18cm; display: flex; justify-content: space-around; white-space: nowrap;">
            <div style="font-size: 12px; transform: scale(0.4); width: 50%; position: relative; right: 0.3cm;">123123021-1212</div>
            <div style="font-size: 12px; transform: scale(0.4); width: 50%; position: relative; left: 0.1cm;">总质量: 5.93g</div>
          </div>
          <div style="width: 1.9cm; height: 0.3cm; position: absolute; top: 1.8cm; left: 0.5cm;">
            <img src="" style="width: 100%; height: 100%;" />
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.13cm; font-size: 12px; transform: scale(0.5);">123456789</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.31cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.4); ">检测依据: GB 11887 GB/T18043</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.48cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市山金矿业贵金属有限公司</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.63cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市罗湖区水贝珠宝工业园</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.83cm; white-space: nowrap; display: flex; justify-content: flex-start;">
            <div style="font-size: 12px; transform: scale(0.41); ">4006106998</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 山东黄金标签钻石 -->
    <div id="label3" v-if="false">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <!-- 此处循环标签 -->
        <div style="width: 2.9cm; height: 3.372cm; margin-top: 0.2cm; position: relative;" >
          <!-- 背景图 -->
          <div
            :style="{
                position: 'absolute',
                inset: 0,
                background: 'url(' + bg + ')',
                backgroundSize: '100% 100%',
              }"
            >
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.50cm; font-size: 12px; transform: scale(0.6);">18K金祖母绿吊坠</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.71cm; font-size: 12px; transform: scale(0.4);">3.6101/1 绿</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.88cm; font-size: 12px; transform: scale(0.4);">0.892/6262</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 1.08cm; font-size: 12px; transform: scale(0.5);">￥284700.00</div>
          <div style="position: absolute; top: 1.20cm; font-size: 12px; transform: scale(0.3); left: -0.25cm; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">钻石</div>
          <div style="position: absolute; font-size: 12px; transform: scale(0.3); top: 1.4cm; left: -1cm;">27087404 S-1231212/211212</div>
          <div style="position: absolute; font-size: 12px; transform: scale(0.3); top: 1.7cm; right: -0.2cm;">总重: 3.80g</div>
          <div style="width: 1.9cm; height: 0.3cm; position: absolute; top: 1.95cm; left: 0.5cm;">
            <img src="" style="width: 100%; height: 100%;" />
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.23cm; font-size: 12px; transform: scale(0.5);">123456789</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.4cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.4); ">检测依据: GB 11887 GB/T18043</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.58cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市山金矿业贵金属有限公司</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.75cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市罗湖区水贝珠宝工业园</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.9cm; white-space: nowrap; display: flex; justify-content: flex-start;">
            <div style="font-size: 12px; transform: scale(0.41); ">4006106998</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 山东硬金足金标签 -->
    <div id="label2" v-if="false">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <!-- 此处循环标签 -->
        <div style="width: 2.9cm; height: 3.372cm; margin-top: 0.2cm; position: relative;" >
          <!-- 背景图 -->
          <div
            :style="{
                position: 'absolute',
                inset: 0,
                background: 'url(' + bg + ')',
                backgroundSize: '100% 100%',
              }"
            >
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.38cm; font-size: 12px; transform: scale(0.7);">足金耳饰</div>
          <!-- <div style="width: 100%; text-align: center; position: absolute; top: 0.61cm; font-size: 12px; transform: scale(0.5);">含金量: 999%</div> -->
          <div style="width: 100%; text-align: center; position: absolute; top: 0.63cm; font-size: 12px; transform: scale(0.6);">净质量: 5.93g</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.83cm; font-size: 12px; transform: scale(0.5);">工费: 222.00</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 1.10cm; display: flex; justify-content: space-around; white-space: nowrap;">
            <div style="font-size: 12px; transform: scale(0.4); width: 50%; position: relative; right: 0.3cm;">123123021-1212</div>
            <div style="font-size: 12px; transform: scale(0.4); width: 50%; position: relative; left: 0.1cm;">总质量: 5.93g</div>
          </div>
          <div style="width: 1.9cm; height: 0.3cm; position: absolute; top: 1.8cm; left: 0.5cm;">
            <img src="" style="width: 100%; height: 100%;" />
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.13cm; font-size: 12px; transform: scale(0.5);">123456789</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.31cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.4); ">检测依据: GB 11887 GB/T18043</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.48cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市山金矿业贵金属有限公司</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.63cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市罗湖区水贝珠宝工业园</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.83cm; white-space: nowrap; display: flex; justify-content: flex-start;">
            <div style="font-size: 12px; transform: scale(0.41); ">4006106998</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 山东黄金足金标签 -->
    <div id="label1" v-if="false">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <!-- 此处循环标签 -->
        <div style="width: 2.9cm; height: 3.372cm; margin-top: 0.2cm; position: relative;" >
          <!-- 背景图 -->
          <div
            :style="{
                position: 'absolute',
                inset: 0,
                background: 'url(' + bg + ')',
                backgroundSize: '100% 100%',
              }"
            >
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.38cm; font-size: 12px; transform: scale(0.7);">足金耳饰</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.61cm; font-size: 12px; transform: scale(0.5);">含金量: 999%</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 0.83cm; font-size: 12px; transform: scale(0.6);">净质量: 5.93g</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 1.08cm; font-size: 12px; transform: scale(0.5);">工费: 222.00</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 1.28cm; display: flex; justify-content: space-around; white-space: nowrap;">
            <div style="font-size: 12px; transform: scale(0.4); width: 50%; position: relative; right: 0.3cm;">123123021-1212</div>
            <div style="font-size: 12px; transform: scale(0.4); width: 50%; position: relative; left: 0.1cm;">总质量: 5.93g</div>
          </div>
          <div style="width: 1.9cm; height: 0.3cm; position: absolute; top: 1.8cm; left: 0.5cm;">
            <img src="" style="width: 100%; height: 100%;" />
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.13cm; font-size: 12px; transform: scale(0.5);">123456789</div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.31cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.4); ">检测依据: GB 11887 GB/T18043</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.48cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市山金矿业贵金属有限公司</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.63cm; white-space: nowrap; display: flex; justify-content: center;">
            <div style="font-size: 12px; transform: scale(0.41); ">深圳市罗湖区水贝珠宝工业园</div>
          </div>
          <div style="width: 100%; text-align: center; position: absolute; top: 2.83cm; white-space: nowrap; display: flex; justify-content: flex-start;">
            <div style="font-size: 12px; transform: scale(0.41); ">4006106998</div>
          </div>
        </div>
      </div>
    </div>
    <div id="text5" v-if="false">
      <!-- 此处循环纸张 -->
      <div
        style="
          width: 10.16cm;
          height: 3.4cm;
          font-size: 12px;
          position: relative;
        "
      >
        <!--  请在此处修改样式里的 top 和 left 值 -->
        <div
          style="
            position: absolute;
            top: 0cm;
            left: 0cm;
            bottom: 0cm;
            right: 0cm;
          "
        >
          <div
            style="
              width: 2.65cm;
              height: 1.7cm;
              display: flex;
              margin-left: 0.55cm;
              justify-content: space-around;
              align-items: center;
              flex-direction: column;
            "
          >
            <div style="text-align: center">18K金项链</div>
            <div style="text-align: center">售价：35380.00元</div>
          </div>
          <div style="width: 3.2cm; height: 1.7cm; position: relative;">
            <div style="width: 1.7cm; height: 0.6cm; margin-left: 1cm; position: absolute; top: 0.8cm;">
              <img style="width: 100%; height: 100%;" src="data:image/png;base64,${barcode}">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="text4" v-if="false">
      <!-- 此处循环纸张 -->
      <div
        style="width: 21cm; height: 14cm; position: relative; font-size: 12px"
      >
        <!--  请在此处修改样式里的 top 和 left 值 -->
        <div
          style="
            position: absolute;
            top: 0cm;
            left: 0cm;
            bottom: 0cm;
            right: 0cm;
          "
        >
          <div style="position: absolute; top: 1.5cm; left: 3.2cm">
            2020-5-20
          </div>
          <div style="position: absolute; top: 2.2cm; left: 3.2cm">
            15:47:17
          </div>
          <div
            style="
              position: absolute;
              top: 2cm;
              left: 18.5cm;
              width: 2cm;
              word-wrap: break-word;
            "
          >
            2045545045
          </div>
          <div
            style="
              width: 20cm;
              height: 7.7cm;
              position: absolute;
              left: 0.3cm;
              top: 3.7cm;
            "
          >
            <!-- 在此处控制要哪个类显示 -->
            <div>
              <!-- 钻石类表头 -->
              <div style="display: flex">
                <div style="flex: 1; text-align: center">货号</div>
                <div style="flex: 1; text-align: center">名称</div>
                <div style="flex: 1; text-align: center">尺寸</div>
                <div style="flex: 1; text-align: center">总重</div>
                <div style="flex: 1; text-align: center">主石重</div>
                <div style="flex: 1; text-align: center">副石重</div>
                <div style="flex: 1; text-align: center">证书号</div>
                <div style="flex: 1; text-align: center">实收价</div>
              </div>
              <!-- 此处循环商品 -->
              <div style="display: flex">
                <div style="flex: 1; text-align: center; word-wrap: break-word">
                  202020202020
                </div>
                <div style="flex: 1; text-align: center">不知道叫什么</div>
                <div style="flex: 1; text-align: center">20cm</div>
                <div style="flex: 1; text-align: center">10g</div>
                <div style="flex: 1; text-align: center">5g</div>
                <div style="flex: 1; text-align: center">5g</div>
                <div style="flex: 1; text-align: center">不知道叫什么证书</div>
                <div style="flex: 1; text-align: center">￥9600.00</div>
              </div>
            </div>
            <!-- 在此处控制要哪个类显示 -->
            <div>
              <!-- 贵金类表头 -->
              <div style="display: flex">
                <div style="flex: 1; text-align: center">货号</div>
                <div style="flex: 1; text-align: center">名称</div>
                <div style="flex: 1; text-align: center">成色</div>
                <div style="flex: 1; text-align: center">金重</div>
                <div style="flex: 1; text-align: center">金价</div>
                <div style="flex: 1; text-align: center">工费</div>
                <div style="flex: 1; text-align: center">实收</div>
                <div style="flex: 1; text-align: center">证书号</div>
              </div>
              <!-- 此处循环商品 -->
              <div style="display: flex">
                <div style="flex: 1; text-align: center; word-wrap: break-word">
                  202020202020
                </div>
                <div style="flex: 1; text-align: center">不知道叫什么</div>
                <div style="flex: 1; text-align: center">20cm</div>
                <div style="flex: 1; text-align: center">10g</div>
                <div style="flex: 1; text-align: center">5g</div>
                <div style="flex: 1; text-align: center">5g</div>
                <div style="flex: 1; text-align: center">不知道叫什么证书</div>
                <div style="flex: 1; text-align: center">￥9600.00</div>
              </div>
            </div>
            <!-- 在此处控制要哪个类显示 -->
            <div>
              <!-- 旧料表头 -->
              <div style="display: flex">
                <div style="flex: 1; text-align: center">货号</div>
                <div style="flex: 1; text-align: center">材质</div>
                <div style="flex: 1; text-align: center">金重</div>
                <div style="flex: 1; text-align: center">主石重</div>
                <div style="flex: 1; text-align: center">回收单价</div>
                <div style="flex: 1; text-align: center">回收金额</div>
              </div>
              <!-- 此处循环商品 -->
              <div style="display: flex">
                <div style="flex: 1; text-align: center; word-wrap: break-word">
                  202020202020
                </div>
                <div style="flex: 1; text-align: center">不知道叫什么</div>
                <div style="flex: 1; text-align: center">20cm</div>
                <div style="flex: 1; text-align: center">10g</div>
                <div style="flex: 1; text-align: center">5g</div>
                <div style="flex: 1; text-align: center">5g</div>
              </div>
            </div>
          </div>
          <div style="position: absolute; left: 2.3cm; top: 11.4cm">谢泽鹏</div>
          <div style="position: absolute; left: 2.3cm; top: 12.1cm">谢泽鹏</div>
          <div style="position: absolute; left: 2.3cm; top: 12.7cm">谢泽鹏</div>
          <div style="position: absolute; left: 1cm; bottom: 0.3cm">
            备注：这是强经理要的货
          </div>
        </div>
      </div>
    </div>
    <div id="text3" v-if="false">
      <!-- 此处循环纸张 -->
      <div
        style="
          width: 3cm;
          height: 3.2cm;
          border: 1px dashed pink;
          position: relative;
          font-size: 12px;
        "
      >
        <!--  请在此处修改样式里的 top 和 left 值 -->
        <div
          style="
            position: absolute;
            top: 0cm;
            left: 0cm;
            bottom: 0cm;
            right: 0cm;
          "
        >
          <div
            style="
              width: 2.6cm;
              position: absolute;
              left: 0.4cm;
              height: 1.6cm;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <div>情侣钻戒</div>
            <div>￥10.00</div>
          </div>
          <div
            style="
              width: 2.6cm;
              position: absolute;
              left: 0.4cm;
              height: 1.6cm;
              top: 1.6cm;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div style="width: 2cm; height: 1.1cm">
              <img
                style="width: 100%; height: 100%"
                src="data:image/png;base64,${barcode}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 梦金园 -->
    <div id="text2" style="font-size: 12px" v-if="false">
      <!-- 此处循环纸张 -->
      <div
        style="
          width: 21cm;
          height: 14cm;
          border: 1px dashed pink;
          position: relative;
        "
      >
        <!--  请在此处修改样式里的 top 和 left 值 -->
        <div
          style="
            position: absolute;
            top: 0cm;
            left: 0cm;
            bottom: 0cm;
            right: 0cm;
          "
        >
          <div style="position: absolute; left: 1cm; top: 2.2cm">
            顾客姓名：HHH
          </div>
          <div style="position: absolute; left: 15.2cm; top: 2.2cm">
            订单号：123456789
          </div>
          <div style="position: absolute; left: 1cm; top: 2.7cm">
            手机号：15975168167
          </div>
          <div style="position: absolute; left: 15.2cm; top: 2.7cm">
            销售顾问：谢先生
          </div>
          <div style="position: absolute; left: 15.2cm; top: 3.2cm">
            日期：2020-02-21 15:00:00
          </div>
          <div
            style="
              width: 19cm;
              height: 6.65cm;
              position: absolute;
              left: 0.95cm;
              top: 3.8cm;
            "
          >
            <div style="display: flex; margin-bottom: 0.2cm; font-weight: bold">
              <div style="flex: 4">货号</div>
              <div style="flex: 4">货品名称</div>
              <div style="flex: 1">数量</div>
              <div style="flex: 1">金重</div>
              <div style="flex: 2">金价</div>
              <div style="flex: 2">销售工费</div>
              <div style="flex: 2">标签价</div>
              <div style="flex: 2">销售金额</div>
            </div>
            <!-- 此处循环商品 -->
            <div style="display: flex">
              <div style="flex: 4">2222aaa</div>
              <div style="flex: 4">素金(按件)ABC</div>
              <div style="flex: 1">1</div>
              <div style="flex: 1">—</div>
              <div style="flex: 2">155.00</div>
              <div style="flex: 2">100/件</div>
              <div style="flex: 2">36666.00</div>
              <div style="flex: 2">1464.85</div>
            </div>
            <div
              style="display: flex; font-weight: bold; margin: 0.32cm 0 0.2cm 0"
            >
              <div style="flex: 1">货号(旧)</div>
              <div style="flex: 1">旧料名称</div>
              <div style="flex: 1">材质成色</div>
              <div style="flex: 1">金重</div>
              <div style="flex: 1">回收金额</div>
            </div>
            <!-- 此处循环旧料 -->
            <div style="display: flex">
              <div style="flex: 1">210400001</div>
              <div style="flex: 1">18K金配件</div>
              <div style="flex: 1">18K</div>
              <div style="flex: 1">12.33</div>
              <div style="flex: 1">111.00</div>
            </div>
            <div style="position: absolute; bottom: 1cm; left: 0.2cm">
              实收金额(大写)：不会打大写字
            </div>
            <div style="position: absolute; bottom: 1cm; right: 0.2cm">
              实收金额：40000
            </div>
            <div style="position: absolute; bottom: 0.5cm; left: 0.2cm">
              <span style="margin-right: 0.5cm">抹零金额：0.18元</span>
              <span style="margin-right: 0.5cm">折扣金额：0.18元</span>
              <span style="margin-right: 0.5cm">整单优惠：0.18元</span>
            </div>
          </div>
          <div
            style="
              position: absolute;
              left: 11cm;
              bottom: 0cm;
              width: 7cm;
              height: 2cm;
            "
          >
            备注：我是备注服不服
          </div>
        </div>
      </div>
    </div>
    <!-- 老凤祥 -->
    <div id="text" style="font-size: 12px" v-if="false">
      <!-- 此处循环纸张 -->
      <div
        style="
          width: 21.1cm;
          height: 14.1cm;
          border: 1px solid black;
          position: relative;
        "
      >
        <div
          style="position: absolute; top: 0cm; left: 0cm; bottom: 0; right: 0"
        >
          <div style="position: absolute; left: 15cm; top: 1cm">
            订单号: 1234579
          </div>
          <div style="position: absolute; left: 5.5cm; top: 3cm">谢泽鹏</div>
          <div style="position: absolute; left: 10.5cm; top: 3cm">
            15975168167
          </div>
          <div style="position: absolute; left: 16cm; top: 3cm">2021</div>
          <div style="position: absolute; left: 16cm; top: 3cm">2021</div>
          <div style="position: absolute; left: 17.7cm; top: 3cm">4</div>
          <div style="position: absolute; left: 18.7cm; top: 3cm">7</div>
          <!-- 商品 -->
          <div style="position: absolute; left: 5.3cm; top: 4.7cm">
            <!-- 此处循环商品 -->
            <div style="height: 0.9cm; display: flex">
              <div
                style="
                  width: 2.7cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                123456789
              </div>
              <div
                style="
                  width: 2.4cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                18K纯金钻石
              </div>
              <div
                style="
                  width: 1.7cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                g
              </div>
              <div
                style="
                  width: 1.7cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                20
              </div>
              <div
                style="
                  width: 1.7cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                5000
              </div>
              <div
                style="
                  width: 1.7cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                50
              </div>
              <div
                style="
                  width: 2.4cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                5000000
              </div>
            </div>
          </div>
          <!-- 饰品调换 -->
          <div style="position: absolute; left: 5.3cm; top: 8.2cm">
            <!-- 此处循环饰品 -->
            <div style="height: 0.9cm; display: flex">
              <div
                style="
                  width: 2.7cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                什么品名嗷
              </div>
              <div
                style="
                  width: 1.3cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                20
              </div>
              <div
                style="
                  width: 2.2cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                20g
              </div>
              <div
                style="
                  width: 2.2cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                20%
              </div>
              <div
                style="
                  width: 2.2cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                30
              </div>
              <div
                style="
                  width: 1.3cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                2000
              </div>
              <div
                style="
                  width: 2.4cm;
                  height: 0.9cm;
                  line-height: 0.9cm;
                  text-align: center;
                "
              >
                20000
              </div>
            </div>
          </div>
          <div style="position: absolute; left: 8.5cm; top: 10.5cm">
            大写钱哦哦
          </div>
          <div style="position: absolute; left: 16.5cm; top: 10.5cm">18000</div>
          <div style="position: absolute; left: 6cm; top: 11.6cm">
            这是谢泽鹏先生的单，打1折
          </div>
          <div style="position: absolute; left: 6cm; top: 12.7cm">陈红明</div>
          <div style="position: absolute; left: 9.7cm; top: 12.7cm">陈红明</div>
          <div style="position: absolute; left: 13.3cm; top: 12.7cm">
            陈红明
          </div>
        </div>
      </div>
    </div>
    <!-- <div id="print" style="font-size: 12px;">
      <div style="font-size: 16px; text-align: center; font-weight: bold;">成品销售单</div>
      <div style="display: flex;  flex-wrap: wrap; margin-top: 20px;">
        <div style="display: flex; margin-right: 20px;">
          <div style="width: 100px;">单据日期：</div>
          <div style="width: 200px; border-bottom: 1px solid black;">2021年4月5日</div>
        </div>
        <div style="display: flex; margin-right: 20px;">
          <div style="width: 100px;">单据编号：</div>
          <div style="width: 200px; border-bottom: 1px solid black;">2021年4月5日</div>
        </div>
        <div style="display: flex; margin-right: 20px;">
          <div style="width: 100px;">商场名称：</div>
          <div style="width: 200px; border-bottom: 1px solid black;">2021年4月5日</div>
        </div>
        <div style="display: flex;">
          <div style="width: 100px;">柜台：</div>
          <div style="width: 200px; border-bottom: 1px solid black;">2021年4月5日</div>
        </div>
      </div>
      <div>
      </div>
    </div> -->
    <!-- <div id="print" style="margin: 0; padding: 0;">
      <div style="width: 21cm; height: 14.5cm; font-size: 12px; border: 1px solid black; position: relative;">
        <div>
          <div style="position: absolute; left: 16.5cm; top: 2.3cm;">2021年4月2日14:13:23</div>
          <div style="position: absolute; left: 16.5cm; top: 2.8cm;">水贝一号分店</div>
          <div style="position: absolute; left: 4.7cm; top: 3.3cm;">01010101010</div>
          <div style="position: absolute; left: 11.3cm; top: 3.3cm;">4544</div>
          <div style="position: absolute; left: 16.5cm; top: 3.3cm;">15975168167</div>
          <div style="position: absolute; left: 1.5cm; top: 5.2cm; right: 2cm; font-size: 14px;">
            <div style="display: flex;">
              <div style="width: 20%; text-align: center;">dff121</div>
              <div style="width: 20%; text-align: center;">钻石18K</div>
              <div style="width: 20%; text-align: center;">2</div>
              <div style="width: 20%; text-align: center;">50</div>
              <div style="width: 20%; text-align: center;">18,000</div>
            </div>
          </div>
          <div style="position: absolute; left: 15.2cm; top: 11.2cm; font-size: 14px;">18,000</div>
          <div style="position: absolute; left: 3.7cm; top: 11.7cm; font-size: 14px;">谢泽鹏</div>
          <div style="position: absolute; left: 8.5cm; top: 11.7cm; font-size: 14px;">陈红明</div>
        </div>
      </div>
    </div> -->
    <!-- <div id="print" class="print" style="width: 1000px; height: 650px; font-size: 14px; padding: 4%; box-sizing: border-box; position: relative;">
      <div style="padding-top: 1%;">
        <div>
          <div style="display: flex; justify-content: flex-end;">
            <div>2021年4月1日15:10:06</div>
          </div>
          <div style="display: flex; justify-content: flex-end;">
            <div>水贝一号店</div>
          </div>
          <div style="display: flex;">
            <div style="width: 33.3%; text-align: right;">151554</div>
            <div style="width: 33.3%; text-align: right;">15154848</div>
            <div style="width: 33.3%; text-align: right;">15975168167</div>
          </div>
        </div>
        <div style="margin-top: 9%; font-weight: bold; font-size: 16px;">
          <div style="display: flex;">
            <div style="width: 20%; text-align: center;">12345648789</div>
            <div style="width: 20%; text-align: center;">18K纯金钻石</div>
            <div style="width: 20%; text-align: center;">1</div>
            <div style="width: 20%; text-align: center;">50</div>
            <div style="width: 20%; text-align: center;">2000</div>
          </div>
        </div>
        <div style="font-weight: bold; font-size: 16px; position: absolute; bottom: 12%; left: 4%; right: 4%;">
          <div style="display: flex; justify-content: flex-end;">
            <div style="width: 20%;">
              18,000,000
            </div>
          </div>
          <div style="display: flex;">
            <div style="width: 30%; text-align: right;">谢泽鹏</div>
            <div style="width: 20%; text-align: right;">陈红梅</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="table">
      <el-table :data="tableData.list" stripe height="700" v-loading="loading">
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            align="center"
            :prop="item.field_alias"
            :label="item.field_text"
            :width="item.t_width"
          >
          </el-table-column>
        </template>
        <el-table-column align="center" prop="action" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="color: #f56c6c"
              @click="handleDele(scope.row)"
              >删除</el-button
            >
            <el-button type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handlePrint(scope.row)"
              >打印测试</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="$store.state.pageSizes"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <el-dialog title="新增打印模板" :visible.sync="dialogVisible" width="55%">
      <el-form :model="form" label-width="120px">
        <el-form-item label="模板名称" required="">
          <el-input v-model="form.template_name" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="模板类型" required>
          <el-select v-model="form.type" placeholder="请选择模板类型">
            <el-option label="销售单" :value="20"></el-option>
            <el-option label="退货单" :value="30"></el-option>
            <el-option label="回收单" :value="40"></el-option>
            <el-option label="标签" :value="50"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分页条数" required="">
          <el-input v-model="form.page" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="旧料分开打印" required="">
          <el-switch
            v-model="form.is_separated"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="纸张宽度(cm)">
          <el-input
            v-model="form.width"
            style="width: 50%"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="纸张高度(cm)">
          <el-input
            v-model="form.height"
            style="width: 50%"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="上偏移(cm)">
          <el-input
            v-model="form.top"
            style="width: 50%"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="左偏移(cm)">
          <el-input
            v-model="form.left"
            style="width: 50%"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="模板内容">
          <el-input
            type="textarea"
            v-model="form.content"
            :rows="10"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleTrue" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="打印测试" :visible.sync="printVisible" width="55%">
      <el-button type="primary" v-print="printObj" style="margin-bottom: 20px"
        >本地打印</el-button
      >
      <div v-html="currNote"></div>
    </el-dialog>
  </div>
</template>

<script>
import { createReq, getListReq, deleReq } from "@/api/system/print/list";
export default {
  data() {
    return {
      bg: require('@/assets/label.png'),
      currNote: "",
      printObj: {
        id: "print",
        popTitle: "销售订单",
      },
      printVisible: false,
      loading: false,
      form: {
        is_separated: 0,
        top: 0,
        left: 0,
      },
      dialogVisible: false,
      rowList: [
        { field_text: "模板名称", field_alias: "template_name" },
        { field_text: "模板类型", field_alias: "type_name" },
        // { field_text: "修改人", field_alias: "index2" },
        // { field_text: "修改时间", field_alias: "index3" },
        { field_text: "创建人", field_alias: "store_user_name" },
        { field_text: "创建时间", field_alias: "create_time" },
      ],
      loading: false,
      tableData: {
        list: [],
        total: 0,
      },
      page: 1,
      limit: this.$store.state.pageSizes[0],
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (!val) {
          this.form = {
            is_separated: 0,
            top: 0,
            left: 0,
          };
        }
      },
    },
  },
  methods: {
    Preview1() {	
    LODOP=getLodop();  
     LODOP.SET_PRINT_MODE("POS_BASEON_PAPER",true);
		LODOP.PRINT_INITA(0,0,522,333,"打印控件功能演示_Lodop功能_自定义纸张1");	
		LODOP.SET_PRINT_PAGESIZE(0,document.getElementById('W1').value,document.getElementById('H1').value,"A4");
		LODOP.ADD_PRINT_HTM(0,0,"100%","100%",document.getElementById('box').innerHTML);
	  	LODOP.PREVIEW();		
  },
  AddPrintContent(strCode,strName) {		
		LODOP.SET_PRINT_STYLE("FontColor",16711680);
		LODOP.ADD_PRINT_RECT(62,16,459,217,0,1);
		LODOP.ADD_PRINT_TEXT(15,137,157,25,"交通银行（      ）");
		LODOP.SET_PRINT_STYLEA(2,"FontName","隶书");
		LODOP.SET_PRINT_STYLEA(2,"FontSize",11);
		LODOP.SET_PRINT_STYLEA(2,"FontColor",0);
		LODOP.ADD_PRINT_TEXT(41,213,100,20,"2008年11月9日");
		LODOP.ADD_PRINT_TEXT(17,281,100,20,"个人业务受理书");
		LODOP.SET_PRINT_STYLEA(4,"FontColor",0);
		LODOP.ADD_PRINT_TEXT(75,37,431,20,"机构:109110 交易代码:010110");
		LODOP.ADD_PRINT_TEXT(102,37,431,20,"个人网银用户签约成功！");
		LODOP.ADD_PRINT_TEXT(129,37,431,20,"网点名称：东城分行营业部");
		LODOP.ADD_PRINT_TEXT(156,37,431,20,"主卡卡号："+strCode);
		LODOP.ADD_PRINT_TEXT(183,37,431,20,"客户姓名："+strName);
		LODOP.ADD_PRINT_TEXT(212,37,431,20,"登陆方式：用户名登陆");
		LODOP.ADD_PRINT_TEXT(17,218,60,20,"东城分行");
		LODOP.ADD_PRINT_TEXT(249,169,221,20,"以上内容已核实确认无误，客户签名：");
		LODOP.ADD_PRINT_TEXT(106,484,23,127,"第二联客户留联");
		LODOP.SET_PRINT_STYLEA(13,"FontColor",0);
		LODOP.ADD_PRINT_TEXT(284,21,74,20,"授权员");
		LODOP.SET_PRINT_STYLEA(14,"FontColor",0);
		LODOP.ADD_PRINT_TEXT(284,200,74,20,"复核员");
		LODOP.SET_PRINT_STYLEA(15,"FontColor",0);
		LODOP.ADD_PRINT_TEXT(284,346,74,20,"经办员");
		LODOP.SET_PRINT_STYLEA(16,"FontColor",0);
	},
    handlePrint(row) {
      this.printVisible = true;
      this.currNote = row.content;
    },
    handleEdit(row) {
      this.form = { ...row };
      this.dialogVisible = true;
    },
    handleDele(row) {
      this.$confirm("确定要删除该打印模板吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleReq({
          print_template_local_id: row.print_template_local_id,
        }).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.getList();
          }
        });
      });
    },
    handleTrue() {
      this.loading = true;
      createReq({ ...this.form })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.$message.success("操作成功");
            this.dialogVisible = false;
            this.getList();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getList() {
      this.loading = true;
      getListReq({
        // ...this.form,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.print {
  background: url("./images/muban.png");
  background-size: cover;
}
</style>
